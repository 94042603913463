import  { createApp } from "vue";
import App from "./App.vue";
import router from './router';

import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';

import * as Sentry from "@sentry/vue";

// import "~/styles/element/index.scss";

// import ElementPlus from "element-plus";
// import all element css, uncommented next line
// import "element-plus/dist/index.css";

// or use cdn, uncomment cdn link in `index.html`

import "~/styles/index.scss";
import "uno.css";

// If you want to use ElMessage, import it.
import "element-plus/theme-chalk/src/message.scss";
// import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'




const app = createApp(App);
app.use(router);
app.component('MazPhoneNumberInput', MazPhoneNumberInput);


Sentry.init({
    app,
    dsn: "https://",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/app\.rileycourier\.com/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, 
  });

// app.use(ElementPlus);
app.mount("#app");

// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/Login.vue';
import Signup from '../components/Signup.vue';



const routes = [

  {
    path: '/',
    name: 'landing',
    component: Signup,
  },
  // { path: '/', redirect: '/request' }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

<script lang="ts" setup>
import { toggleDark } from "~/composables";
import { ref, inject } from "vue";
import router from "../../router";
// import { useAuthStore } from "../../store/authStore";

// const store = useAuthStore();

const activeIndex = ref("1");
const handleSelect = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};
const fetchedPanda = ref(localStorage.getItem("_panda"));

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1]; // Get the second part of the token (payload)
    const base64 = base64Url.replace("-", "+").replace("_", "/"); // Replace URL-safe characters
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (er) {
    return "";
  }
};

const logwat = () => {};
const registerSession = (data: any, token: string) => {};

const showFirstName = () => {};
</script>

<template>
  <el-header>
    <el-menu class="el-menu header-bloc" mode="horizontal">
      <el-menu-item index="3">RTM Africa</el-menu-item>
    </el-menu>
  </el-header>
</template>
<style scoped>
.header-bloc,
.header-bloc__authenticated {
  /* background-color: #4a8b5942;s */
  background-color: var(--ep-color-primary);
}
.ep-menu--horizontal > .ep-menu-item,
.header-bloc__authenticated > .ep-sub-menu__title {
  color: #fff;
}
.ep-header {
  padding: 0;
}
.header-bloc {
  display: block;
  margin: 0 auto;
}
.flex-grow {
  flex-grow: 1;
}
a.router-link-active,
.ep-menu-item a {
  text-decoration: none;
}
</style>

<script setup lang="ts">
import { reactive, ref, onBeforeUnmount, computed } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ElMessage } from "element-plus";
import axios from "axios";
import router from "../router";

const loadingSearch = ref(false);
const loadingPay = ref(false);
const loadingPoll = ref(false); //false
const showSearchForm = ref(true); // true
const showPaymentForm = ref(false);
const showPollForm = ref(false);
const formSize = ref("default");
const regNo = ref("");
const phoneNo = ref(254);
const paymentRetries = ref(0);
const timer = ref(<any>null);

interface searchResponse {
  status: boolean;
  park_id: number;
  parking_paid: boolean;
  number_plate: string;
  time_in: string;
  time_out: string;
  time_to_leave: string;
  time_spent: number;
  readable_time_spent: string;
  community_name: string;
  time_paid: string;
  amount_due: number;
  amount_paid: number;
  balance: number;
}

interface searchObject {
  value: searchResponse;
}
const emptySearch = {
  status: false,
  park_id: 0,
  parking_paid: false,
  number_plate: "",
  time_in: "2024-01-01 00:00:00",
  time_out: "2024-01-01 00:00:00",
  time_to_leave: "2024-01-01 00:00:00",
  time_spent: 0,
  readable_time_spent: "0 hours 0 minutes",
  time_paid: "2024-01-01 00:00:00",
  amount_due: 0,
  amount_paid: 0,
  balance: 0,
  community_name: "",
};

const searchResults = reactive<searchObject>({ value: emptySearch });

const headerText = ref("Enter your number plate to proceed to pay");

const submitSearch = () => {
  console.log("loadingSearch", loadingSearch.value);
  if (!loadingSearch.value) {
    if (regNo.value) {
      // loadingSearch.value = true;
      // showPaytitle.value = false;

      postSearch();
    } else {
      ElMessage({
        message: "Please enter the number plate",
        type: "error",
      });
    }
  } else {
    console.log("not gonna duplicate search");
  }
};

const submitPayment = () => {
  console.log("loadingPay", loadingPay.value);
  if (!loadingPay.value) {
    if (phoneNo.value) {
      if (phoneNo.value.toString().length <= 3) {
        ElMessage({
          message: "Please enter your M-Pesa number to pay",
          type: "error",
        });
      } else if (phoneNo.value.toString().length === 12) {
        postPayment();
      } else {
        ElMessage({
          message: "Please a valid your M-Pesa number to pay",
          type: "error",
        });
      }
    } else {
      ElMessage({
        message: "Please enter your M-Pesa number to pay",
        type: "error",
      });
    }
  } else {
    console.log("not gonna duplicate payment");
  }
};
const queryPayment = async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  const postData = {
    number_plate: regNo.value,
    type: "check_to_pay",
  };

  try {
    const response = await axios.post(
      `${import.meta.env.VITE_PARKING_SERVICE}check`,
      postData,
      { headers }
    );
    return response;
  } catch (er) {
    return er;
  }
};

const postSearch = async () => {
  loadingSearch.value = true;
  const headers = {
    "Content-Type": "application/json",
  };
  const postData = {
    number_plate: regNo.value,
    type: "check_to_pay",
  };
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_PARKING_SERVICE}check`,
      postData,
      { headers }
    );
    // Handle the API response here
    console.log("response");
    console.log(response);
    if (response.status == 200) {
      if (response.data.status) {
        if (response.data.balance <= 0) {
          ElMessage({
            message: "The payment was already recieved, please proceed to checkout.",
            type: "info",
          });
        } else {
          showSearchForm.value = false;
          showPaymentForm.value = true;
          headerText.value = "Your parking details";
          searchResults.value = response.data;
        }

        // router.push("/login");
      } else {
        ElMessage({
          message: response.data.data,
          type: "error",
        });
      }
      loadingSearch.value = false;
    } else {
      ElMessage({
        message: "The payment status could not be fetched",
        type: "error",
      });
      loadingSearch.value = false;
    }

    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    ElMessage({
      message: "Something went wrong when processing your request, please retry",
      type: "error",
    });
    console.error("Error:", error);
    loadingSearch.value = false;
  }
};

const postPayment = async () => {
  loadingPay.value = true;
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_PAYMENT_SERVICE}requestMoney`,
      {
        amount: searchResults.value.balance,
        phone_number: phoneNo.value,
        contact_name: `Parking Fees - ${searchResults.value.number_plate}`,
        parking_ref: searchResults.value.park_id.toString(),
        reference: `${searchResults.value.park_id}-${getRandomInt(1, 100)}`,
        description: `${searchResults.value.number_plate} parking fees`,
      }
    );
    console.log(response);
    // Handle the API response here
    if (response.status == 200) {
      if (response.data.status) {
        if (response.data.balance <= 0) {
          ElMessage({
            message: "The payment was already recieved, please proceed to checkout.",
            type: "info",
          });
        } else {
          ElMessage({
            message:
              "The request has been processed successfully. Pease complete the payment when prompted on your phone",
            type: "success",
          });
          showPaymentForm.value = false;
          showPollForm.value = true;
          headerText.value = "Confirming your payment";
          loadingPoll.value = true;
          startPoll();
        }
      } else {
        ElMessage({
          message: response.data.message,
          type: "error",
        });
      }
    } else {
      ElMessage({
        message: "The payment status could not be fetched",
        type: "error",
      });
    }
    loadingPay.value = false;
    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    ElMessage({
      message: "Something went wrong when processing your request, please retry",
      type: "error",
    });
    console.error("Error:", error);
    loadingPay.value = false;
  }
};
const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const cancelPoll = () => {
  showPollForm.value = false;
  showPaymentForm.value = false;
  showSearchForm.value = true;
  clearInterval(timer.value);
  paymentRetries.value = 0;
};

const startPoll = () => {
  timer.value = setInterval(() => {
    // Your function logic goes here
    console.log("Function executed every 3 seconds.");

    queryPayment()
      .then((result: any) => {
        console.log("poll result", result);
        if (result.status === 200) {
          console.log("poll result in 200");
          if (result.data.status) {
            console.log("poll result in data status");
            if (result.data.balance <= 0) {
              console.log("poll result balance 0");
              ElMessage({
                message:
                  "The payment has beeen received successfully. Please exit within 15 mins",
                type: "success",
                duration: 0,
                showClose: true,
              });
              cancelPoll();
              clearInterval(timer.value);
              headerText.value = "Enter your number plate to proceed to pay";
              // regNo.value = "";
            } else {
              console.log("searchResults.value.balance", searchResults.value.balance);
              console.log("result.data.balance", result.data.balance);
              if (result.data.amount_paid !== searchResults.value.amount_paid) {
                ElMessage({
                  message: `Please pay KES ${result.data.balance} to complete your payment`,
                  type: "warning",
                  duration: 0,
                  showClose: true,
                });
                cancelPoll();
                clearInterval(timer.value);
                headerText.value = "Enter your number plate to proceed to pay";
                // regNo.value = "";
              }
              console.log("poll result balance was not 0");
            }
          }
        } else {
          console.log("poll result status", result.status);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });

    paymentRetries.value++;
    if (paymentRetries.value >= 5) {
      cancelPoll();
      clearInterval(timer.value);
      ElMessage({
        message: "The payment could not be confirmed, please retry",
        type: "error",
      });
    }
  }, 10000); // 30 sec
};

onBeforeUnmount(() => {
  // Clear the interval when the component is destroyed to prevent memory leaks
  cancelPoll();
});
// startPoll();
</script>
<template>
  <div w="full" class="login-wrapper" id="signup-wrapper">
    <el-row>
      <el-col :xs="24" :sm="6" :md="8" :lg="8"></el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8">
        <div class="grid-content auth-box --el-box-shadow">
          <div class="auth-title">
            {{ headerText }}
          </div>
          <p></p>
          <el-form
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-if="showSearchForm"
          >
            <el-form-item prop="contact_name">
              <el-input
                v-model.trim="regNo"
                placeholder="Enter number plate"
                size="large"
                maxlength="7"
                clearable
              />
            </el-form-item>

            <div style="margin: 30px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitSearch()"
                class="auth-sign-in-button"
                size="large"
                :disabled="regNo.length === 0"
                v-loading="loadingSearch"
              >
                Search
              </el-button>
            </el-form-item>
          </el-form>
          <el-form
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-if="showPaymentForm"
          >
            <el-card class="box-card">
              <template #header>
                <div class="card-header">
                  <span>{{ searchResults.value.number_plate }}</span>
                  <!-- <el-button class="button" text>Operation button</el-button> -->
                </div>
              </template>
              <div class="text item" style="padding: 5px">
                Station: {{ searchResults.value.community_name }}
              </div>
              <div class="text item">
                Duration: {{ searchResults.value.readable_time_spent }}
              </div>
              <div class="text item" style="padding: 5px">
                Parking Fee: {{ searchResults.value.amount_due }}
              </div>
              <div class="text item">Service Fee: 0</div>
              <div
                class="text item"
                style="font-weight: 500; font-size: large; padding: 5px"
              >
                Total: KES {{ searchResults.value.balance }}
              </div>
              <template #footer>Footer content</template>
            </el-card>
            <div style="margin: 30px 0" />

            <el-form-item prop="contact_name">
              <el-input
                v-model.number="phoneNo"
                placeholder="Enter your phone number to pay with M-Pesa"
                size="large"
                maxlength="12"
                :controls="false"
              />
            </el-form-item>

            <div style="margin: 30px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitPayment()"
                class="auth-sign-in-button"
                size="large"
                :disabled="phoneNo.toString().length !== 12"
                v-loading="loadingPay"
              >
                Pay with M-Pesa
              </el-button>
            </el-form-item>
            <div style="margin: 20px"></div>
            <el-link type="primary" @click="cancelPoll()">Back</el-link>
          </el-form>
          <el-form
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-if="showPollForm"
          >
            <div v-loading="loadingPoll">
              <div style="margin: 30px 0" />

              <!-- <el-form-item prop="contact_name">
                <el-input
                  v-model.number="phoneNo"
                  placeholder="Enter your phone number to pay with M-Pesa"
                  size="large"
                  maxlength="12"
                  :controls="false"
                />
              </el-form-item> -->
              <p>&nbsp;</p>
              <div style="margin: 30px 0" />
            </div>
            <el-form-item>
              <el-button
                type="primary"
                @click="cancelPoll()"
                class="auth-sign-in-button"
                size="large"
              >
                Back
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="8" :lg="8"></el-col>
    </el-row>
  </div>
</template>
<style scoped>
.auth-box {
  border: 0px solid #7c7f822e;
  border-radius: 3px;
  padding: 0 3rem;
}
.auth-title {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}
.login-wrapper {
  margin: 2.5rem 0;
}
sign-up-wrapper {
  margin: 2.5rem 0;
}
.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
.accept-terms {
  position: relative;
  margin-left: -20px;
  margin-top: 1px;
  font-size: 12px;
}
div.m-phone-number-input.holder-maz-input {
  width: 100%;
}
.m-input-wrapper --default-border maz-rounded {
  border-radius: 5px;
}
</style>
